<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card no-header>
			<div slot="body" style="position: relative;">
				<el-button type="primary" size="mini" @click="insert()">新建</el-button>
				<div style="position: absolute;right: 10px;top: -3px;">
					<el-input style="width: 200px;" size="small" placeholder="请输入采集站名" v-model="searchParm.name">
						<el-button slot="append" icon="el-icon-search" @click="list()"></el-button>
					</el-input>
				</div>
			</div>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column prop="name" label="采集站名">
							</el-table-column>
							<el-table-column prop="no" label="采集站编号">
							</el-table-column>
							<el-table-column prop="ent_name" label="企业名">
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="120">
								<template slot-scope="scope">
									<el-button type="text" size="small" @click="edit('编辑',scope.row)">
										编辑
									</el-button>
									<el-button type="text" size="small" @click="remove(scope.row)">
										删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<insert :isShow.sync="showLookDialog"></insert>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import smFooter from "@c/smFooter.vue"
	import insert from "./insert.vue"
	import edit from "./edit.vue"
	export default {
		components: {
			smCard,
			smFooter,
			insert,
			edit
		},
		data() {
			return {
				tableHeight: 0,
				eqName: "",
				tableData: {
					records: [],
				},
				searchParm: {},
				showLookDialog: false,
				showEditDialog: false,
				editObj: {}
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			insert() {
				this.showLookDialog = true;
			},
			edit(title, row) {
				this.editObj = {
					title: title,
					row: row,
				};
				this.showEditDialog = true;
			},
			remove(row) {
				this.$confirm('是否删除(' + row.name + ',' + row.no + ')', '删除采集站', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$remove("/backend-api/eqp/sta/remove/" + row.id, null).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			list() {
				this.$get("/backend-api/eqp/sta/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
						console.log(res.data);
					} else {
						this.$message.error(res.msg);
					}
				});
			}
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";
</style>
